import {
    Button,
    Collapse,
    DISPLAY_ICON,
    Gap,
    H3,
    Link,
    Panel,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { Diff } from "@/components/Diff/Diff.component";
import {
    getIMUpdateStep,
    updateStep,
} from "@/components/Integrations/ImUpdate/ImUpdate.component";
import { STEPS_ESTIMATIONS_SECONDS } from "@/components/Integrations/ImUpdate/constants";
import {
    isProcessDone,
    isProcessRunning,
    useStepErrorHandling,
} from "@/components/Integrations/ImUpdate/helpers";
import { useIntegrationUpdateStepState } from "@/components/Integrations/ImUpdate/loadables";
import Progress from "@/components/Progress/Progress";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { ReactComponent as AngleRight } from "@pricefx/unity-components/src/icons/unicons/angle-right-b.svg";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles.less";

export const CodeAnalysisStep = ({
    instanceId,
    process,
    onNextStep,
    onReAnalyze,
    onCancel,
    wasAlreadyCompleted = false,
}) => {
    const { integrationManagerUpdateService, messageService } = useDic();

    const anaylzeResource = useIntegrationUpdateStepState({
        instanceId,
        step: "analyze",
        canFetch:
            process?.type ===
                getIMUpdateStep(updateStep.CODE_ANALYSIS).sseType &&
            isProcessDone(process),
    });
    const anaylzeResult = anaylzeResource.loadable.valueMaybe();
    useStepErrorHandling(anaylzeResult);

    const canSkipCodeModification = anaylzeResult
        ? isEmpty(anaylzeResult?.changes)
        : false;

    return (
        <>
            {isProcessRunning(process) ? (
                <>
                    <Text size="large">
                        {t("im-instance.update.code-analysis.perex", {
                            seconds: STEPS_ESTIMATIONS_SECONDS.ANALYSIS,
                        })}
                    </Text>
                    <Gap />
                    <Progress
                        className={styles.progress}
                        showInfo
                        percent={process.progress}
                        message="Analysing..."
                    />
                </>
            ) : isProcessDone(process) ? (
                <>
                    <H3>{t("im-instance.update.code-analysis.result")}</H3>

                    {isEmpty(anaylzeResult?.changes) ? (
                        <Text size="large">
                            {t("im-instance.update.code-analysis.empty-result")}
                        </Text>
                    ) : (
                        anaylzeResult.changes.map((change, i) => (
                            <>
                                <Text size="large">
                                    {`${change.type || ""} ${change.itemUrl}`}
                                    <Link
                                        href={change.itemUrl}
                                        targetBlank
                                        displayIcon={DISPLAY_ICON.always}
                                        style={{
                                            marginLeft: "12px",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {t(
                                            "im-instance.update.code-analysis.view-in-git",
                                        )}
                                    </Link>
                                </Text>
                                <Collapse ghost>
                                    <Panel header={change.reasonList?.[0]}>
                                        <Diff
                                            oldValue={
                                                change.objectContentDiff
                                                    .oldContent
                                            }
                                            newValue={
                                                change.objectContentDiff
                                                    .newContent
                                            }
                                        />
                                    </Panel>
                                </Collapse>
                            </>
                        ))
                    )}
                    <Gap size="medium" />
                    <H3>{t("im-instance.update.continue-to-next-step")}</H3>
                    <Text size="large">
                        {t(
                            "im-instance.update.code-analysis.continue-to-next-step-perex",
                        )}
                    </Text>
                </>
            ) : null}

            <Gap size="medium" />
            {canSkipCodeModification ? (
                <Button
                    type="primary"
                    label={t("im-instance.update.button.start-update")}
                    data-test="start-update-button"
                    rightIcon={AngleRight}
                    onClick={() => {
                        if (!wasAlreadyCompleted) {
                            integrationManagerUpdateService
                                .finishUpdate(instanceId)
                                .then(getData)
                                .then(onNextStep(updateStep.FINISH_UPDATE));
                        } else {
                            onNextStep(updateStep.FINISH_UPDATE)();
                        }
                    }}
                    disabled={isProcessRunning(process)}
                />
            ) : null}
            <Button
                type={!canSkipCodeModification ? "primary" : "secondary"}
                label={t("im-instance.update.button.code-modification")}
                disabled={
                    isProcessRunning(process) || !!anaylzeResult?.exception
                }
                rightIcon={AngleRight}
                data-test="code-modification-button"
                onClick={() => {
                    if (!wasAlreadyCompleted) {
                        integrationManagerUpdateService
                            .startModification(instanceId)
                            .then(getData)
                            .then(onNextStep(updateStep.CODE_MODIFICATION));
                    } else {
                        onNextStep(updateStep.CODE_MODIFICATION)();
                    }
                }}
            />

            <Button
                disabled={isProcessRunning(process)}
                label={t("im-instance.update.button.re-analyze")}
                data-test="re-analyze-button"
                onClick={() =>
                    integrationManagerUpdateService
                        .startAnalysis(instanceId, anaylzeResult.versionTo)
                        .then(getData)
                        .then(onReAnalyze)
                        .catch(e =>
                            messageService.error({
                                content: getErrorMessage(e.response.data),
                            }),
                        )
                }
            />
            <Button
                type="link"
                data-test="cancel-button"
                label={t("general.cancel")}
                onClick={onCancel}
            />
        </>
    );
};

CodeAnalysisStep.propTypes = {
    instanceId: PropTypes.number.isRequired,
    process: PropTypes.object.isRequired,
    onNextStep: PropTypes.func.isRequired,
    onReAnalyze: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    wasAlreadyCompleted: PropTypes.bool,
};
