import {
    Alert,
    Button,
    Forms,
    Gap,
    Link,
    Popover,
} from "@/components/DesignSystem";
import { MAX_INT_INSTANCES } from "@/components/ImInstancesLimit/AccountInstancesLimitModal";
import {
    isPastDate,
    isValidExpirationDateValidator,
} from "@/components/Integrations/EditInstanceForm";
import { useIntegrationSupportedTagsQuery } from "@/components/Integrations/loadables";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import {
    isLoading,
    LoadableRenderer,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { SecurityContext } from "@/security/authorization";
import {
    ACCOUNT_INTEGRATION_ADMIN_FUNC,
    ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    hasPermission,
} from "@/security/permission.utils";
import { t } from "@/translations";
import { DATE_FORMAT } from "@pricefx/unity-components/dist/es/constants";
import { Alert as AntdAlert } from "antd";
import { filter, get, map, pipe, update, values } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useRef } from "react";
import { useInstanceEnvironmentsOptionsResource } from "./loadables";

const uniqueEnvNameValidator = async (value, getBag) => {
    const { values } = await getBag();

    return Object.values(values.environments).filter(e => e.envName === value)
        .length > 1
        ? Forms.error(t("general.validation.duplicated-value"))
        : Forms.success();
};

const { isRequired } = Forms.pmValidators;
const LAYOUT = [
    { width: "95px" },
    { width: "115px" },
    { width: "95px" },
    { width: "95px" },
    { width: "95px" },
    {},
    { width: "150px" },
    { width: "195px" },
    { width: "25px" },
];

const toOptions = map(({ name }) => ({
    value: name,
    label: name,
}));
const isExisting = field => field("isExisting")?.initialValue;

const canDeleteEnv = field =>
    field("canDeleteEnv")?.initialValue === undefined ||
    field("canDeleteEnv")?.initialValue;
const isOptDisabled = (env, envs) =>
    envs.some(({ envName, isExisting }) => env === envName && isExisting);
const to = map(
    update("expiration", ex => (ex ? moment(ex, DATE_FORMAT.DATE) : undefined)),
);

const limitReached = ({
    rows,
    initialValue,
    maxIntegrationInstances,
    existingInstances,
}) => {
    const totalInstances =
        existingInstances + (rows.length - initialValue.length);

    return totalInstances >= (maxIntegrationInstances ?? MAX_INT_INSTANCES);
};

const StatusComponent = ({
    status,
    envName,
    instanceGroupName,
    instanceId,
    forceDeletable,
    instancesRestartMutation,
    instancesDeleteMutation,
}) => {
    switch (status) {
        case "DELETE_FAILED":
        case "DELETE_REQUESTED":
        case "REQUESTED":
        case "MISSING_IN_GIT":
            return (
                <Popover
                    title={t("instance-form.status.delete-failed")}
                    content={
                        <>
                            {t(
                                "instance-form.status.delete-failed.popover.text",
                            )}
                            {forceDeletable && (
                                <>
                                    {t(
                                        "instance-form.status.delete-failed.popover.text2",
                                    )}
                                    <Gap size="medium" />
                                    {
                                        <Link
                                            danger
                                            onClick={() =>
                                                instancesDeleteMutation.mutate({
                                                    instanceId,
                                                    queryParams: {
                                                        forceDelete: true,
                                                    },
                                                })
                                            }
                                        >
                                            {t(
                                                "instance-list.menu.force-delete",
                                            )}
                                        </Link>
                                    }
                                </>
                            )}
                        </>
                    }
                    overlayStyle={{ width: "230px" }}
                >
                    {" "}
                    <Alert
                        type="warning"
                        message={t("instance-form.status.delete-failed")}
                    />
                </Popover>
            );
        case "MISSING_IN_PLATFORM":
            return (
                <Popover
                    title={t("instance-form.status.missing-in-git")}
                    content={
                        <>
                            {t(
                                "instance-form.status.missing-in-git.popover.text",
                            )}
                            <Gap size="medium" />
                            {
                                <Link
                                    onClick={() =>
                                        instancesRestartMutation.mutate({
                                            instanceName: `${instanceGroupName}-${envName}`,
                                            type: envName,
                                        })
                                    }
                                >
                                    {t(
                                        "instance-form.status.missing-in-git.popover.link",
                                    )}
                                </Link>
                            }
                        </>
                    }
                    overlayStyle={{ width: "230px" }}
                >
                    {" "}
                    <Alert
                        type="warning"
                        message={t("instance-form.status.missing-in-git")}
                    />
                </Popover>
            );
        default:
            return null;
    }
};

export const ProvisionedInstanceEnvironments = React.memo(
    ({
        initialValue: initialValueProp = [],
        maxIntegrationInstances,
        existingInstances,
        newEnvVersion,
        name = "environments",
        disableEnvsEdit = false,
        instanceGroupName,
        isNewInstance = false,
        instancesRestartMutation,
        instancesDeleteMutation,
    }) => {
        const securityContext = useContext(SecurityContext);
        const canSetAdvancedOptions =
            ACCOUNT_INTEGRATION_ADMIN_FUNC(securityContext);
        const hasPermissionToSetExpiration = hasPermission(
            securityContext.permissions,
            ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
        );

        const { current: initialValue } = useRef(to(initialValueProp));
        const value = Forms.useFieldValue({ formId: Forms.useFormId(), name });
        const selectedEnvironments = useMemoByDeepEquality(
            pipe(values, map(get("envName")), filter(Boolean))(value),
        );
        const optionsQuery = useInstanceEnvironmentsOptionsResource({
            canSetAdvancedOptions,
        });
        const environmentOptionsLoadable = useMapLoadableMemoized(
            optionsQuery.loadable,
            useCallback(
                ([environments]) =>
                    environments
                        .map(env => env.name)
                        .filter(env => !selectedEnvironments.includes(env))
                        .map(env => ({
                            label: env,
                            value: env,
                            disabled: isOptDisabled(env, initialValue),
                        })),
                [initialValue, selectedEnvironments],
            ),
        );

        const integrationTagsResource =
            useIntegrationSupportedTagsQuery(instanceGroupName);
        const integrationVersionsOptions = useMapLoadableMemoized(
            integrationTagsResource.loadable,
            map(({ name }) => ({
                label: name,
                value: name,
            })),
        );

        return (
            <LoadableRenderer
                loadable={optionsQuery.loadable}
                hasValue={([, cpus = [], mems = [], storages = []] = []) => (
                    <Forms.FieldGrid layout={LAYOUT}>
                        <Forms.List name={name} initialValue={initialValue}>
                            {({ rows, add, remove }) => (
                                <>
                                    {rows.map(({ id, fieldProps }) => {
                                        const instanceId =
                                            fieldProps(
                                                "instanceId",
                                            )?.initialValue;
                                        const status =
                                            fieldProps("status")?.initialValue;
                                        const envName =
                                            fieldProps("envName")?.initialValue;
                                        const forceDeletable =
                                            fieldProps(
                                                "forceDeletable",
                                            )?.initialValue;
                                        return (
                                            <Forms.FieldGrid.Row key={id}>
                                                <Hidden
                                                    {...fieldProps(
                                                        "isExisting",
                                                    )}
                                                />
                                                <Forms.Fields.Select
                                                    label={t(
                                                        "instance-form.label.environment",
                                                    )}
                                                    {...fieldProps("envName")}
                                                    required
                                                    validator={Forms.validators.failOnFirst(
                                                        [
                                                            isRequired,
                                                            uniqueEnvNameValidator,
                                                        ],
                                                    )}
                                                    {...getLoadableSelectProps(
                                                        environmentOptionsLoadable,
                                                    )}
                                                    disabled={
                                                        disableEnvsEdit ||
                                                        isExisting(fieldProps)
                                                    }
                                                />
                                                <Forms.Fields.Select
                                                    required
                                                    label={t(
                                                        "instance-form.label.cpu",
                                                    )}
                                                    {...fieldProps("cpu")}
                                                    options={toOptions(cpus)}
                                                    validator={isRequired}
                                                    disabled={
                                                        !canSetAdvancedOptions ||
                                                        (disableEnvsEdit &&
                                                            isExisting(
                                                                fieldProps,
                                                            ))
                                                    }
                                                    initialValue={cpus[0]?.name}
                                                />
                                                <Forms.Fields.Select
                                                    required
                                                    label={t(
                                                        "instance-form.label.memory",
                                                    )}
                                                    {...fieldProps("mem")}
                                                    options={toOptions(mems)}
                                                    validator={isRequired}
                                                    disabled={
                                                        !canSetAdvancedOptions ||
                                                        (disableEnvsEdit &&
                                                            isExisting(
                                                                fieldProps,
                                                            ))
                                                    }
                                                    initialValue={mems[0]?.name}
                                                />
                                                <Forms.Fields.Select
                                                    required
                                                    label={t(
                                                        "instance-form.label.storage",
                                                    )}
                                                    {...fieldProps("storage")}
                                                    options={toOptions(
                                                        storages,
                                                    )}
                                                    validator={isRequired}
                                                    disabled={
                                                        !canSetAdvancedOptions ||
                                                        (disableEnvsEdit &&
                                                            isExisting(
                                                                fieldProps,
                                                            ))
                                                    }
                                                    initialValue={
                                                        storages[0]?.name
                                                    }
                                                />
                                                <Forms.Fields.DatePicker
                                                    label={t(
                                                        "instance-form.label.expiration",
                                                    )}
                                                    {...fieldProps(
                                                        "expiration",
                                                    )}
                                                    disabled={
                                                        !canSetAdvancedOptions ||
                                                        isExisting(
                                                            fieldProps,
                                                        ) ||
                                                        !hasPermissionToSetExpiration
                                                    }
                                                    disabledDate={isPastDate}
                                                    validator={Forms.pmValidators.noValueOr(
                                                        isValidExpirationDateValidator,
                                                    )}
                                                />
                                                {isNewInstance ? (
                                                    <Forms.Fields.ReadOnlyValue
                                                        label={t(
                                                            "instance-form.label.version",
                                                        )}
                                                        {...fieldProps(
                                                            "version",
                                                        )}
                                                        defaultValue={
                                                            newEnvVersion
                                                        }
                                                    />
                                                ) : (
                                                    <Forms.Fields.Select
                                                        required
                                                        {...fieldProps(
                                                            "version",
                                                        )}
                                                        label={t(
                                                            "instance-form.label.version",
                                                        )}
                                                        showSearch
                                                        validator={
                                                            !isExisting(
                                                                fieldProps,
                                                            )
                                                                ? Forms.validators.failOnFirst(
                                                                      [
                                                                          Forms
                                                                              .pmValidators
                                                                              .isRequired,
                                                                          !isLoading(
                                                                              integrationVersionsOptions,
                                                                          ) &&
                                                                              Forms.pmValidators.createAllowedValuesValidator(
                                                                                  integrationVersionsOptions
                                                                                      .valueMaybe()
                                                                                      ?.map(
                                                                                          item =>
                                                                                              item.value,
                                                                                      ) ??
                                                                                      [],
                                                                                  "Value not found in options",
                                                                              ),
                                                                      ],
                                                                  )
                                                                : undefined
                                                        }
                                                        disabled={isExisting(
                                                            fieldProps,
                                                        )}
                                                        {...getLoadableSelectProps(
                                                            integrationVersionsOptions,
                                                        )}
                                                    />
                                                )}
                                                <Forms.UIField
                                                    name="notes"
                                                    label={t(
                                                        "instance-form.label.notes",
                                                    )}
                                                >
                                                    <StatusComponent
                                                        status={status}
                                                        envName={envName}
                                                        instanceId={instanceId}
                                                        instanceGroupName={
                                                            instanceGroupName
                                                        }
                                                        forceDeletable={
                                                            forceDeletable
                                                        }
                                                        instancesRestartMutation={
                                                            instancesRestartMutation
                                                        }
                                                        instancesDeleteMutation={
                                                            instancesDeleteMutation
                                                        }
                                                    />
                                                </Forms.UIField>
                                                <Forms.UIField name="delete">
                                                    <DeleteRowButton
                                                        danger
                                                        onClick={() =>
                                                            remove(id)
                                                        }
                                                        disabled={
                                                            rows.length <= 1 ||
                                                            !canDeleteEnv(
                                                                fieldProps,
                                                            )
                                                        }
                                                    />
                                                </Forms.UIField>
                                            </Forms.FieldGrid.Row>
                                        );
                                    })}
                                    {limitReached({
                                        rows,
                                        initialValue,
                                        maxIntegrationInstances,
                                        existingInstances,
                                    }) && (
                                        <>
                                            <Gap size="small" />
                                            <AntdAlert
                                                showIcon
                                                type="warning"
                                                // message="TODO"
                                                message={t(
                                                    "increase-ims-limit.form.hint",
                                                    {
                                                        // link: t(
                                                        //     "increase-ims-limit.form.hint-link-text",
                                                        // ),

                                                        // TODO: not working with UC Alert
                                                        link: (
                                                            <Link
                                                                targetBlank
                                                                href="https://pricefx.atlassian.net/wiki/display/USERKB/IT+Helpdesk"
                                                            >
                                                                {t(
                                                                    "increase-ims-limit.form.hint-link-text",
                                                                )}
                                                            </Link>
                                                        ),
                                                    },
                                                )}
                                            />
                                        </>
                                    )}
                                    <Gap size="small" />
                                    <Button
                                        size="small"
                                        label={t("instance-form.button.add")}
                                        onClick={() => add()}
                                        disabled={
                                            !environmentOptionsLoadable.valueMaybe()
                                                ?.length ||
                                            limitReached({
                                                rows,
                                                initialValue,
                                                maxIntegrationInstances,
                                                existingInstances,
                                            })
                                        }
                                    />
                                </>
                            )}
                        </Forms.List>
                    </Forms.FieldGrid>
                )}
            />
        );
    },
);

ProvisionedInstanceEnvironments.propTypes = {
    initialValue: PropTypes.array,
    maxIntegrationInstances: PropTypes.number,
    existingInstances: PropTypes.number,
    newEnvVersion: PropTypes.string,
    name: PropTypes.string,
    disableEnvsEdit: PropTypes.bool,
    instanceGroupName: PropTypes.string,
    isNewInstance: PropTypes.bool,
    instancesRestartMutation: PropTypes.func.isRequired,
};
