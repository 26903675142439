import {
    Button,
    ButtonGroup,
    Forms,
    Gap,
    H3,
    H5,
    P,
    Text,
} from "@/components/DesignSystem";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";

const SUPPORTED_UNIT = "Gi";

const EngineImage = ({ imageMaybe }) => {
    if (!imageMaybe) return null;

    return (
        <>
            <H5>{t("calculation-engine.form.machines.label-edit")}</H5>
            <Text>{imageMaybe}</Text>
        </>
    );
};

export const CalculationEngineForm = ({
    presets,
    initialValues,
    onSubmit,
    onCancel,
    isNew,
}) => {
    const { formId, handleSubmit, setValues } = Forms.useForm({
        initialValues,
        onSubmit: ({ values }) => onSubmit(values),
    });

    const { config: { cpu = {}, memory = {} } = {}, engines = [] } = presets;

    useEffect(() => {
        if (
            engines.some(
                e => e.memoryLimit && e.memoryLimit?.units !== SUPPORTED_UNIT,
            )
        )
            throw new Error(
                `Preset engine contains unsupported unit, expected '${SUPPORTED_UNIT}'. Check presets API response.`,
            );
        if (
            [memory.min.units, memory.max.units].some(u => u !== SUPPORTED_UNIT)
        )
            throw new Error(
                `Presets config contains unsupported unit, expected '${SUPPORTED_UNIT}'. Check presets API response.`,
            );
    }, [engines, memory.max.units, memory.min.units]);

    const engineId = Forms.useFieldValue({ formId, name: "engineId" });
    // Some engines not listed in presets.engines:
    const selectedEngineMaybe = engines.find(e => e.engineId === engineId);
    const selectedEngineImage = useMemo(
        () =>
            selectedEngineMaybe?.image ??
            (isNew ? undefined : initialValues?.name),
        [selectedEngineMaybe?.image, isNew, initialValues?.name],
    );
    const engineReadOnly = !isNew && selectedEngineImage;
    const supportLimits = Forms.useFieldValue({
        formId,
        name: "supportLimits",
    });

    const machinesOptions = useMemo(
        () =>
            engines.map(eng => ({
                value: eng.engineId,
                label: `${eng.name}${
                    eng.image?.replace(/^/, " (")?.replace(/$/, ")") || ""
                }`,
            })),
        [engines],
    );
    const onMachineChange = useCallback(
        field => {
            const machine = engines.find(
                ({ engineId }) => engineId === field.value,
            );
            if (machine)
                setValues({
                    cpuLimit: machine.cpuLimit,
                    memoryLimit: machine.memoryLimit?.value,
                    // memoryUnit: machine.memoryLimit?.units,
                    supportLimits: machine.supportLimits, // TODO
                });
            else
                setValues({
                    cpuLimit: "",
                    memoryLimit: "",
                    supportLimits: false,
                });
        },
        [engines, setValues],
    );
    const cpuValidator = useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.min(cpu.min ?? 0),
                Forms.pmValidators.max(cpu.max ?? Infinity),
            ]),
        [cpu.max, cpu.min],
    );
    const memoryValidator = useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.min(memory.min.value ?? 0),
                Forms.pmValidators.max(memory.max.value ?? Infinity),
            ]),
        [memory.max.value, memory.min.value],
    );

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <P>{t("calculation-engine.perex")}</P>
            <Forms.Fields.Input name="supportLimits" type="hidden" />
            {engineReadOnly ? (
                <Forms.Fields.Input name="engineId" type="hidden" />
            ) : (
                <Forms.Fields.Select
                    required
                    name="engineId"
                    label={t(
                        isNew
                            ? "calculation-engine.form.machines.label-new"
                            : "calculation-engine.form.machines.label-edit",
                    )}
                    disabled={!isNew}
                    options={machinesOptions}
                    validator={Forms.pmValidators.isRequired}
                    onChange={onMachineChange}
                />
            )}
            <H3>
                <T id="calculation-engine.machine-config" />
            </H3>
            <EngineImage imageMaybe={selectedEngineImage} />
            {!!supportLimits && (
                <>
                    <Forms.Fields.InputNumber
                        required
                        name="cpuLimit"
                        label={t("calculation-engine.form.cpu.label")}
                        tooltip={t("calculation-engine.form.cpu.tooltip")}
                        validator={cpuValidator}
                    />
                    <Forms.Fields.InputNumber
                        required
                        name="memoryLimit"
                        label={t("calculation-engine.form.memory.label")}
                        tooltip={t("calculation-engine.form.memory.tooltip")}
                        validator={memoryValidator}
                        addonAfter={SUPPORTED_UNIT}
                    />
                    <Hidden name="memoryUnit" initialValue={SUPPORTED_UNIT} />
                </>
            )}
            <Forms.Fields.TextArea
                name="reason"
                label={t("calculation-engine.form.reason.label")}
                required
                validator={Forms.pmValidators.isRequired}
                tooltip={t("calculation-engine.form.reason.tooltip")}
            />
            {/* <Forms.Field
                as={DatePicker.RangePicker}
                from={identity}
                name="validTo"
                label={t("calculation-engine.form.validTo.label")}
                format="DD/MM/YYYY"
                placeholder={["from", "to"]}
            /> */}
            <H3>{t("calculation-engine.request-workflow")}</H3>
            <Text>{t("calculation-engine.request-workflow-desc")}</Text>
            <Gap size="large" />
            <ButtonGroup>
                <Forms.SubmitButton formId={formId} disabled={false}>
                    <Button
                        label={t("calculation-engine.action.request")}
                        type="primary"
                        htmlType="submit"
                        formId={formId}
                    />
                </Forms.SubmitButton>
                <Button
                    label={t("general.cancel")}
                    type="text"
                    htmlType="button"
                    onClick={onCancel}
                />
            </ButtonGroup>
        </Forms.Form>
    );
};

CalculationEngineForm.propTypes = {
    presets: PropTypes.object,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
};
