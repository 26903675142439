import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { ISV_CONNECTION_STATUS } from "@/components/ISVConnections/steps/General.step";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import {
    getErrorMessage,
    getErrorMessageFromError,
} from "@/utils/state/error.utils";
import { noop } from "lodash";
import { tap } from "lodash/fp";
import { MAPPER_DIRECTION } from "../ISVMapping/steps/General.step";

export const useConnectionsListQuery = ({ accountId, reloadToken }) => {
    const { isvConnectionService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(accountId)
                ? pendingPromise()
                : isvConnectionService.listConnections(accountId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accountId, reloadToken],
    );
};

export const useConnectionErrorsQuery = ({
    isvConnectionId,
    type = MAPPER_DIRECTION.DOWNLOAD,
    reloadToken,
}) => {
    const { isvConnectionService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(isvConnectionId)
                ? pendingPromise()
                : isvConnectionService.getConnectionErrors({
                      isvConnectionId,
                      type,
                  }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isvConnectionId, reloadToken],
    );
};

export const useRefreshSettingsMutation = () => {
    const { isvConnectionService } = useDic();

    return useMutationLoadableWithNotification(
        async connectionId =>
            isvConnectionService.refreshSettings(connectionId),
        [],
        ({ name }) =>
            t("isv-connections.steps.vendor.resend-to-partition.success"),
        getErrorMessage,
    );
};
export const useDeleteConnectionMutation = ({ afterSuccess = noop }) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            isvConnectionService.deleteConnection(record.id).then(res => {
                afterSuccessCurrent();
                return res;
            }),
        [afterSuccessCurrent],
        ({ name }) =>
            t("general.message.deleted", { type: "connection", name }),
        getErrorMessage,
    );
};
export const useCreateConnectionMutation = ({ afterSuccess = noop }) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async connection =>
            isvConnectionService.createConnection(connection).then(res => {
                afterSuccessCurrent(res);
                return res;
            }),
        [afterSuccessCurrent],
        ({ name }) =>
            t("general.message.created", { type: "connection", name }),
        getErrorMessage,
    );
};
export const useEditConnectionMutation = ({ connectionId, afterSuccess }) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async connection =>
            isvConnectionService
                .editConnection(connectionId, connection)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                }),
        [afterSuccessCurrent],

        ({ name }) => t("general.saved", { type: "connection", name }),
        getErrorMessageFromError,
    );
};

export const useChangeISVConnectionStatusMutation = ({
    afterSuccess = noop,
} = {}) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ connectionId, state }) =>
            isvConnectionService
                .changeStatus(connectionId, state)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, isvConnectionService],
        ({ state, name }) =>
            t("general.name-saved-as", {
                name,
                value:
                    state === ISV_CONNECTION_STATUS.ACTIVE
                        ? t("general.active")
                        : t("general.inactive"),
            }),
    );
};

export const useISVAllowedPartitionsQuery = ({ accountId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/isv-connection-data/${accountId}/allowed-partitions`)
                .then(getData),
        [accountId, axiosService],
    );
};
