import { Button, ButtonGroup, Forms, Gap, H5 } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    IM_VERSION_WITH_MAX_LENGTH_SUPPORT_1,
    IM_VERSION_WITH_MAX_LENGTH_SUPPORT_2,
} from "@/components/Integrations/constants";
import MapperRows from "@/components/Mappers/form/MapperRows";
import { useMapperExistsValidator } from "@/components/Mappers/validators";
import { useParentState } from "@/components/hooks/useParentState.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { getMapperFormTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { useRouteInstance } from "@/mixpanel/hooks/useRouteInstance.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { isOlder, isSameOrNewer } from "@/utils/versionUtils";
import { entries, fromPairs, map, omit, pipe, reduce } from "lodash/fp";
import React, { useMemo } from "react";
import uuid from "uuid/v4";
import MapperImportFromXml from "./MapperImportFromXml";

const { useForm, Fields, pmValidators } = Forms;

const CONVERT_EMPTY_VALUES_OPTIONS = [
    { value: false, label: t("mapper.convert-empty.empty") },
    { value: true, label: t("mapper.convert-empty.null") },
];

const MAPPER_TYPE = {
    INTEGRATE_MAPPER: "integrateMapper",
    LOAD_MAPPER: "loadMapper",
};

const ACTION = {
    DEPLOY: "DEPLOY",
    SAVE: "SAVE",
};

const getFullName = propertyName => `properties.${propertyName}`;

export const useMapperNameValidator = ({ initialValues, instanceId }) => {
    const mapperExistsValidator = useMapperExistsValidator({
        instanceId,
        skipValidationFor: initialValues?.name,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                debounceAsync(500, mapperExistsValidator),
            ]),
        [mapperExistsValidator],
    );
};

const useMapperTypesQuery = () => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        () =>
            axiosService
                .get(`/api/mappers/types`)
                .then(getData)
                .then(
                    map(({ properties, ...rest }) => ({
                        properties: pipe(
                            map(({ name, ...rest }) => [
                                name,
                                { name, ...rest },
                            ]),
                            fromPairs,
                        )(properties),
                        ...rest,
                    })),
                )
                .then(
                    pipe(
                        map(({ name, properties }) => [name, properties]),
                        fromPairs,
                    ),
                ),
        [axiosService],
    );
};

const CUSTOM_PROPERTIES = {
    convertEmptyStringToNull: "convertEmptyStringToNull",
    excludeProperties: "excludeProperties",
};

const RENDERERS = {
    _DEFAULT: ({ propertyDef, fullName, ...props }) => {
        const tsKey = `mapper-form.form.${propertyDef.name}`;
        const translatedLabel = t(tsKey);
        const label =
            translatedLabel === tsKey ? propertyDef.name : translatedLabel;

        return (
            <Fields.Checkbox
                inputWidth="max"
                name={fullName}
                label={label}
                {...props}
            />
        );
    },
    [CUSTOM_PROPERTIES.convertEmptyStringToNull]: ({ fullName, ...props }) => (
        <Fields.Radio
            name={fullName}
            label={t("mapper-form.form.send-empty-value-as")}
            options={CONVERT_EMPTY_VALUES_OPTIONS}
            validator={Forms.pmValidators.isRequired}
            {...props}
        />
    ),
    [CUSTOM_PROPERTIES.excludeProperties]: ({ fullName, ...props }) => (
        <Fields.Select_tempFixDeselect
            mode="tags"
            name={fullName}
            label={t("mapper-form.form.excludeProperties")}
            {...props}
        />
    ),
};

const MapperProperty = ({
    name,
    fullName,
    properties,
    initialValues,
    readOnly,
}) => {
    const propertyDef = properties[name];
    if (!propertyDef) return null;

    return (RENDERERS[name] || RENDERERS._DEFAULT)({
        disabled: readOnly,
        initialValue: initialValues?.[name] ?? propertyDef.defaultValue,
        propertyDef,
        fullName,
    });
};

const MapperProperties = ({
    mapperTypes,
    mapperType,
    readOnly,
    initialValues,
}) => {
    const properties = mapperTypes[mapperType];

    if (!properties) return null;

    const restProperties = omit(Object.values(CUSTOM_PROPERTIES), properties);
    const commonProps = {
        properties,
        initialValues,
        readOnly,
    };

    return (
        <>
            <MapperProperty
                fullName={getFullName(
                    CUSTOM_PROPERTIES.convertEmptyStringToNull,
                )}
                name={CUSTOM_PROPERTIES.convertEmptyStringToNull}
                {...commonProps}
            />
            <MapperProperty
                fullName={getFullName(CUSTOM_PROPERTIES.excludeProperties)}
                name={CUSTOM_PROPERTIES.excludeProperties}
                {...commonProps}
            />

            {mapperType === MAPPER_TYPE.LOAD_MAPPER ? (
                <H5>{t("mapper-form.form.props-and-records")}</H5>
            ) : (
                <Gap />
            )}
            {Object.values(restProperties).map(({ name }) => (
                <MapperProperty
                    key={name}
                    fullName={getFullName(name)}
                    name={name}
                    {...commonProps}
                />
            ))}
        </>
    );
};

const MapperForm = ({
    initialValues: passedInitialValues,
    instanceId,
    saveMapper,
    onCancel,
    withXmlImport,
    readOnly = false,
    withDeploy,
    textModeMapper,
}) => {
    const [initialValues, setInitialValues] =
        useParentState(passedInitialValues);
    // List does not react to new values, so we need to reinitialize it https://pricefx.atlassian.net/browse/PFIM-7614
    const reinitializeKey = useMemo(() => uuid(), [initialValues]);

    const importFromXmlDialog = useVisibility();
    const nameValidator = useMapperNameValidator({ instanceId, initialValues });
    const mapperTypesQuery = useMapperTypesQuery();

    const { instanceLoadable } = useRouteInstance();

    const isSupportingMaxLengthProperty =
        (isSameOrNewer(
            instanceLoadable.valueMaybe()?.imVersion,
            IM_VERSION_WITH_MAX_LENGTH_SUPPORT_1[0],
        ) &&
            isOlder(
                instanceLoadable.valueMaybe()?.imVersion,
                IM_VERSION_WITH_MAX_LENGTH_SUPPORT_1[1],
            )) ||
        isSameOrNewer(
            instanceLoadable.valueMaybe()?.imVersion,
            IM_VERSION_WITH_MAX_LENGTH_SUPPORT_2,
        );

    const { Form, submit, formId, handleSubmit, setValues, setTouched } =
        useForm({
            onSubmit: async ({ values: formValues, args: [action] }) => {
                const values = pipe(
                    entries,
                    reduce((acc, [name, value]) => {
                        if (name.startsWith("properties.")) {
                            return {
                                ...acc,
                                properties: {
                                    ...acc.properties,
                                    [name.replace(/^properties\./, "")]: value,
                                },
                            };
                        } else {
                            return { ...acc, [name]: value };
                        }
                    }, {}),
                )(formValues);

                if (
                    !formValues["properties.includeUnmappedProperties"] &&
                    !formValues.definition?.length
                ) {
                    return;
                    // TODO: UC https://gitlab.pricefx.eu/platform/services/platform-manager-fe/-/merge_requests/1063/diffs
                    // rest.setErrors({ definition: Forms.error("At least one row is required"), });
                }
                // await rest.revalidate();

                return saveMapper({
                    values: { ...values },
                    withDeploy: action === ACTION.DEPLOY,
                });
            },
        });

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues,
            setTouched,
        },
        [initialValues, setValues, setTouched],
    );

    const type = Forms.useFieldValue({ formId, name: "type" });
    const includeUnmappedProperties = Forms.useFieldValue({
        formId,
        name: getFullName("includeUnmappedProperties"),
    });

    const handleImportFromXmlOk = initialValues => {
        setInitialValues(initialValues);
        importFromXmlDialog.hide();
    };

    return (
        <LoadableRenderer
            loadable={mapperTypesQuery.loadable}
            hasValue={mapperTypes => (
                <>
                    <MapperImportFromXml
                        visible={importFromXmlDialog.visible}
                        onOk={handleImportFromXmlOk}
                        onCancel={importFromXmlDialog.hide}
                    />

                    <Form disableBanner onSubmit={handleSubmit}>
                        <Fields.Input
                            name="name"
                            type="text"
                            required
                            label={t("mapper-form.form.mapper-name.label")}
                            validator={nameValidator}
                            placeholder={t(
                                "mapper-form.form.mapper-name.placeholder",
                            )}
                            disabled={readOnly}
                        />

                        <Fields.Select
                            name="type"
                            placeholder={t("mapper-form.form.type.placeholder")}
                            label={t("mapper-form.form.type.label")}
                            required
                            validator={pmValidators.isRequired}
                            options={[
                                {
                                    value: MAPPER_TYPE.INTEGRATE_MAPPER,
                                    label: MAPPER_TYPE.INTEGRATE_MAPPER,
                                },
                                {
                                    value: MAPPER_TYPE.LOAD_MAPPER,
                                    label: MAPPER_TYPE.LOAD_MAPPER,
                                },
                            ]}
                            disabled={readOnly}
                        />

                        {!textModeMapper ? (
                            <MapperRows
                                key={reinitializeKey}
                                instanceId={instanceId}
                                formId={formId}
                                readOnly={readOnly}
                                setValues={setValues}
                                initialDefinition={
                                    initialValues?.definition ?? []
                                }
                                isOptional={includeUnmappedProperties === true}
                                isSupportingMaxLengthProperty={
                                    isSupportingMaxLengthProperty
                                }
                            />
                        ) : (
                            <Fields.TextArea
                                name="definitionXml"
                                label="Mapper"
                                disabled
                            />
                        )}

                        <MapperProperties
                            mapperTypes={mapperTypes}
                            mapperType={type}
                            readOnly={readOnly}
                            initialValues={initialValues.properties}
                        />

                        <ButtonGroup>
                            <Button
                                formId={formId}
                                visible={!readOnly && withDeploy}
                                type="primary"
                                htmlType="button"
                                onClick={() => submit(ACTION.DEPLOY)}
                                label={t("general.deploy")}
                                track={{ name: getTrackName("Deploy") }}
                            />
                            <Button
                                formId={formId}
                                visible={withXmlImport && !readOnly}
                                htmlType="button"
                                disabled={readOnly}
                                onClick={importFromXmlDialog.show}
                                label={t("mapper-form.button.import-from-xml")}
                                track={{ name: getTrackName("ImportFromXml") }}
                            />
                            <Button
                                formId={formId}
                                visible={!readOnly}
                                htmlType="button"
                                onClick={() => submit(ACTION.SAVE)}
                                label={t("general.save")}
                                track={{ name: getTrackName("Save") }}
                            />
                            <Button
                                formId={formId}
                                type="text"
                                htmlType="button"
                                onClick={onCancel}
                                label={t("general.cancel")}
                                track={{ name: getTrackName("Cancel") }}
                            />
                        </ButtonGroup>
                    </Form>
                </>
            )}
        />
    );
};

export default MapperForm;
