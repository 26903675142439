import { FieldCustomizationModal } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Button, Forms, Switch } from "@/components/DesignSystem";
import { Gap } from "@/components/DesignSystem/Gap";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { EntityNameHeader } from "@/components/Mappers/MapperTableWithCustomization/components/EntityNameHeader.component";
import { getAllowedChangableColumnTypes } from "@/components/Mappers/MapperTableWithCustomization/components/MapperCascaderWithType.component";
import { MapperRow } from "@/components/Mappers/MapperTableWithCustomization/components/MapperRow.component";
import { VENDOR } from "@/components/Mappers/MapperTableWithCustomization/constants";
import { conformToOutputType } from "@/components/Mappers/form/mapper.utils";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { size } from "lodash";
import { pipe, values } from "lodash/fp";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import uuid from "uuid";
import ConditionalErrorAlert from "../../Error/ConditionalErrorAlert";
import { Loading } from "../../Loading/Loading";
import {
    InputType,
    ParsedType,
} from "../../Packages/PackageTableDefinitionPanel/constants";
import "./MapperTableWithCustomization.styles.less";

const inputTypeOptions = Object.values(InputType);

export const ROW_ENTITIES = {
    id: "id",
    input: "input",
    inputType: "inputType",
    output: "output",
    outputType: "outputType",
    converter: "converter", // only converter name => should be deprecated on BE soon
    converterExpression: "converterExpression", // converterName with params
    formulaMapping: "formulaMapping",
};
const transformValue = ({
    name,
    value,
    input,
    inputType,
    outputType,
    parserConfig,
}) => {
    if (!parserConfig) {
        throw new Error("Missing parser config");
    }
    if (name === ROW_ENTITIES.input && inputType === "composed") {
        return value;
    } else if (
        [ROW_ENTITIES.input, ROW_ENTITIES.output].includes(name) &&
        Array.isArray(value)
    ) {
        // multiselect
        if (size(value) > 1) {
            return value;
        } else {
            return value.join("");
        }
    } else if (
        [ROW_ENTITIES.converter, ROW_ENTITIES.converterExpression].includes(
            name,
        )
    ) {
        const converterExpression =
            value ||
            conformToOutputType({ input, inputType, outputType }, parserConfig);
        return converterExpression;
    } else {
        return value;
    }
};

const getValuesIndexedByKey = (values, usedIds, parserConfig) =>
    usedIds.reduce(
        (result, id) => ({
            ...result,
            [id]: Object.keys(ROW_ENTITIES).reduce(
                (result, entryName) => ({
                    ...result,
                    [entryName]: transformValue({
                        name: entryName,
                        value: values[`${id}_${entryName}`],
                        input: values[`${id}_input`],
                        inputType: values[`${id}_inputType`],
                        outputType: values[`${id}_outputType`],
                        parserConfig,
                    }),
                }),
                {},
            ),
        }),
        {},
    );

const getFormValues = pipe(getValuesIndexedByKey, values);

const inputsToTransform = [ROW_ENTITIES.input, ROW_ENTITIES.output];

const formatInitialValue = (name, value) => {
    return inputsToTransform.includes(name)
        ? Array.isArray(value)
            ? value
            : [value]
        : value;
};
const mapInitialValuesToForm = mapperRows =>
    mapperRows.reduce(
        (result, row) => ({
            ...result,
            ...Object.entries(row).reduce((result, [name, value]) => {
                return {
                    ...result,
                    [`${row.id}_${name}`]: formatInitialValue(name, value),
                };
            }, {}),
        }),
        {},
    );

export const useInputOptionsWithTypes = ({ tableExampleData }) => {
    const tableExampleDataMap = useMemo(() => {
        const map = new Map();

        tableExampleData.columns.forEach(row => {
            map.set(row.name, row);
        });

        return map;
    }, [tableExampleData]);

    const tableExampleDataMapRef = useRef(tableExampleDataMap);

    useEffect(() => {
        if (
            tableExampleDataMap.size !== 0 &&
            tableExampleDataMapRef.current === undefined
        ) {
            tableExampleDataMapRef.current = tableExampleDataMap;
        }
    }, [tableExampleDataMap]);

    const inputOptionsWithTypes = useMemo(
        () =>
            tableExampleData.columns.map(column => ({
                label: column.name,
                value: column.name,
                type: column.type,
                allowedToChange: getAllowedChangableColumnTypes(
                    tableExampleDataMapRef.current.get(column.name).type,
                ),
            })),
        [tableExampleData],
    );

    return { inputOptionsWithTypes, tableExampleDataMap };
};

const LAYOUT = [
    { flex: "0 0 35%" },
    { flex: "0 0 16%" },
    { flex: "0 0 35%" },
    { width: 32 },
];
export const defaultVendors = [VENDOR.PRICEFX, VENDOR.PRICEFX];

export const MapperTableWithCustomization = ({
    direction = MAPPER_DIRECTION.UPLOAD,
    vendors = defaultVendors,
    mapper: mapperRows = [],
    errors,
    apiErrors,
    onChange,
    outputOptions: outputOptionsWithTypes = [],
    readOnly,
    tableExampleData,
    inputOptionsWithTypes,
    tableExampleDataMap,
    withConverterWarning,
    parserConfig,
    entityName,
    entityType,
    setValues,
    setTouched,
    getBag,
    onColumnTypeChange,
    partitionId,
}) => {
    const modalControl = useDetailDrawerState();
    const [usedIds, setUsedIds] = useState(mapperRows.map(({ id }) => id));
    const handleChange = useCallback(
        changedIds => {
            getBag().then(({ values }) => {
                const indicies = changedIds ?? usedIds;
                const formValues = getFormValues(
                    values,
                    indicies,
                    parserConfig,
                );
                logger.debug({
                    logGroupKey: [
                        "DATALOAD",
                        "MapperTableWithCustomization",
                        "handleChange",
                    ],
                    color: "orchid",
                    msg: "TODO: not working",
                    data: { values, indicies, parserConfig, formValues },
                });
                setValues(mapInitialValuesToForm(formValues));
                onChange(formValues);
            });
        },
        [getBag, onChange, parserConfig, setValues, usedIds],
    );

    useEffect(() => {
        setTimeout(() => {
            handleChange(usedIds);
        }, 200);
    }, []);

    useSetValidatedInitialValues(
        {
            initialValues: mapInitialValuesToForm(
                getFormValues(
                    mapInitialValuesToForm(mapperRows),
                    usedIds,
                    parserConfig,
                    inputOptionsWithTypes,
                ),
            ),
            setValues,
            setTouched,
        },
        [],
    );

    const detailInfo = useVisibility(true);

    const onConverterClick = useCallback(
        async id => {
            const { values } = await getBag();
            modalControl.show(
                getValuesIndexedByKey(values, usedIds, parserConfig)[id],
            );
        },
        [getBag, modalControl, parserConfig, usedIds],
    );

    const onDelete = useCallback(
        id => {
            setUsedIds(ids => {
                const changedIds = ids.filter(entryId => entryId !== id);
                handleChange(changedIds);
                return changedIds;
            });
        },
        [handleChange],
    );

    return (
        <Loading isLoading={!mapperRows}>
            <div
                className="pmMapperTableWithCustomization"
                data-test="mapper-table-with-customization"
            >
                {[]
                    .concat(errors?.general ?? [])
                    .concat(apiErrors?.messages || (apiErrors ?? []))
                    .map((error, index) => (
                        <React.Fragment
                            key={error?.message ?? JSON.stringify(error)}
                        >
                            <ConditionalErrorAlert key={index} error={error} />
                            <Gap size="small" />
                        </React.Fragment>
                    ))}

                <Switch
                    id="detail-switch"
                    size="small"
                    value={detailInfo.visible}
                    onChange={detailInfo.toggle}
                    textOn={t("data-upload.mapper.detail-view.label")}
                    textOff={t("data-upload.mapper.detail-view.label")}
                />

                <Gap size="small" />

                <EntityNameHeader
                    vendors={vendors}
                    layout={LAYOUT}
                    entityName={entityName}
                    entityType={entityType}
                    partitionId={partitionId}
                />
                <Forms.FieldGrid layout={LAYOUT}>
                    {usedIds.map((id, i) => (
                        <MapperRow
                            key={id}
                            id={id}
                            vendors={vendors}
                            order={i}
                            errors={errors?.inputs ?? []}
                            readOnly={readOnly}
                            tableExampleData={tableExampleData}
                            tableExampleDataMap={tableExampleDataMap}
                            inputOptionsWithTypes={inputOptionsWithTypes}
                            onConverterClick={onConverterClick}
                            onChange={handleChange}
                            onDelete={onDelete}
                            isDetailVisible={detailInfo.visible}
                            isDeleteDisabled={size(usedIds) === 1}
                            outputOptionsWithTypes={outputOptionsWithTypes}
                            converterProps={{
                                withConverterWarning,
                                parserConfig,
                            }}
                            onTypeChange={setValues}
                            onColumnTypeChange={onColumnTypeChange}
                            direction={direction}
                        />
                    ))}
                </Forms.FieldGrid>
                <Gap />
                {!readOnly && (
                    <Button
                        type="tertiary"
                        onClick={() => {
                            setUsedIds(ids => [...ids, uuid()]);
                            handleChange();
                        }}
                        label={"+ " + t("data-upload.mapper.add.field")}
                    />
                )}
                <Gap />
                <FieldCustomizationModal
                    visible={!!modalControl.record}
                    onSave={changedRow => {
                        setValues(mapInitialValuesToForm([changedRow]));
                        handleChange();
                        modalControl.hide();
                    }}
                    onCancel={modalControl.hide}
                    tableExampleData={tableExampleData}
                    initialValues={modalControl.record}
                    withConverterExpression
                    parserConfig={parserConfig}
                    direction={direction}
                />
            </div>
        </Loading>
    );
};

MapperTableWithCustomization.propTypes = {
    vendors: PropTypes.arrayOf(PropTypes.string),
    mapper: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.number.isRequired,
            ]).isRequired,
            input: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            inputType: PropTypes.oneOf(inputTypeOptions),
            output: PropTypes.string,
            outputType: PropTypes.oneOf([...Object.values(ParsedType), ""]),
            formulaMapping: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    sourceField: PropTypes.string.isRequired,
                    example: PropTypes.number.isRequired,
                }).isRequired,
            ),
        }).isRequired,
    ).isRequired,
    outputOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired, // output field name
            type: PropTypes.oneOf(Object.values(ParsedType)).isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    tableExampleData: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.oneOf(Object.values(ParsedType)).isRequired,
                name: PropTypes.string.isRequired,
                parsedValues: PropTypes.array,
            }),
        ),
    }).isRequired,
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    entityName: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    partitionId: PropTypes.number,
    parserConfig: PropTypes.object,
    withConverterWarning: PropTypes.bool,
    apiErrors: PropTypes.string, // ???
};
