import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React from "react";
import {
    HeaderTabs,
    useHeaderTabs,
} from "../PageLayout/components/HeaderTabs.component";
import { ISVConnectionOverview } from "./ISVConnectionOverview";
import { ISVLogHistory } from "./ISVLogHistory";

export const ISV_CONN_TABS = {
    OVERVIEW: "OVERVIEW",
    LOG_HISTORY: "LOG_HISTORY",
};

const ISVConnectionsOverviewPage = () => {
    useFullWidthTableLayout();
    const { accountId, isvConnectionId, processId } = useAccountAppParams();
    const { activeKey } = useHeaderTabs();
    const { isvConnectionLoadable } = useRouteISVConnection();

    return (
        <HeaderTabs
            defaultActiveKey={ISV_CONN_TABS.OVERVIEW}
            destroyInactiveTabPane
        >
            <HeaderTabs.TabPane
                tab={t("isv.overview.tab.overview")}
                key={ISV_CONN_TABS.OVERVIEW}
            >
                <ISVConnectionOverview
                    visible={activeKey === ISV_CONN_TABS.OVERVIEW}
                    accountId={accountId}
                    isvConnectionId={isvConnectionId}
                    isvConnectionLoadable={isvConnectionLoadable}
                />
            </HeaderTabs.TabPane>
            <HeaderTabs.TabPane
                tab={t("isv.overview.tab.log-history")}
                key={ISV_CONN_TABS.LOG_HISTORY}
            >
                <ISVLogHistory
                    visible={activeKey === ISV_CONN_TABS.LOG_HISTORY}
                    accountId={accountId}
                    processId={processId}
                    isvConnectionId={isvConnectionId}
                />
            </HeaderTabs.TabPane>
        </HeaderTabs>
    );
};

export default ISVConnectionsOverviewPage;
