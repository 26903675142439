import {
    Button,
    ButtonMenu,
    Forms,
    Gap,
    Input,
    Separator,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import {
    useCreateOrUpdateNotificationMutation,
    useNotificationsListQuery,
} from "@/components/ISVConnections/notifications.loadables";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { isEmpty } from "lodash/fp";
import React from "react";

const InputWithDelete = ({ deleteProps, ...props }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "4px",
                flexDirection: "row",
                position: "relative",
            }}
        >
            <Input {...props} />
            <DeleteRowButton
                readOnly={deleteProps.readOnly}
                onClick={deleteProps.onRemove}
                style={{
                    position: "absolute",
                    right: "-36px",
                    top: "0",
                }}
            />
        </div>
    );
};

const getDirectionsOptions = vendor => {
    const vendorName = mapVendorToVendorName(vendor);
    return [
        {
            label: "All directions",
            value: "ALL_DIRECTIONS",
        },
        {
            label: `Pricefx to ${vendorName}`,
            value: `PRICEFX_TO_${vendor}`,
        },
        {
            label: `${vendorName} to Pricefx`,
            value: `${vendor}_TO_PRICEFX`,
        },
    ];
};

export const EmailNotificationsStep = ({ isvConnectionId, onCancel }) => {
    const { accountId } = useAccountAppParams();

    const notificationsResource = useNotificationsListQuery({
        isvConnectionId,
        accountId,
    });

    const notificationMutation = useCreateOrUpdateNotificationMutation({
        isvConnectionId,
        accountId,
    });

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: async ({ values }) =>
            notificationMutation.mutate(values.notifications),
    });

    const directionOptions = getDirectionsOptions("ENABLE");

    return (
        <UnityLayout>
            <UnityLayout.Content padding={[true, true, false, true]}>
                <div style={{ maxWidth: "600px" }}>
                    <Text>
                        {t("isv-connections.steps.email-notifications.perex")}
                    </Text>
                    <Gap />
                    <LoadableRenderer
                        loadable={notificationsResource.loadable}
                        hasValue={({ notifications }) => {
                            return (
                                <Forms.Form
                                    formId={formId}
                                    onSubmit={handleSubmit}
                                >
                                    <Forms.List
                                        name="notifications"
                                        initialValue={
                                            !isEmpty(notifications)
                                                ? notifications
                                                : [{ email: "" }]
                                        }
                                    >
                                        {({ rows, add, remove }) => (
                                            <>
                                                {rows.map(
                                                    (
                                                        { id, fieldProps },
                                                        index,
                                                    ) => (
                                                        <div
                                                            className="pmDeleteRow"
                                                            key={id}
                                                        >
                                                            <Forms.Field
                                                                required
                                                                as={
                                                                    InputWithDelete
                                                                }
                                                                type="text"
                                                                label={t(
                                                                    "isv-connections.steps.email-notifications.form.email.label",
                                                                )}
                                                                {...fieldProps(
                                                                    "email",
                                                                )}
                                                                deleteProps={{
                                                                    onRemove:
                                                                        () =>
                                                                            remove(
                                                                                id,
                                                                            ),
                                                                    // readOnly: rows.length === 1,
                                                                }}
                                                                autoComplete={
                                                                    rows.length ===
                                                                    index + 1
                                                                        ? "email"
                                                                        : "nofill"
                                                                }
                                                                validator={Forms.validators.failOnFirst(
                                                                    [
                                                                        Forms
                                                                            .pmValidators
                                                                            .isRequired,
                                                                        Forms
                                                                            .pmValidators
                                                                            .emailValid,
                                                                    ],
                                                                )}
                                                            />

                                                            <Forms.Fields.Select
                                                                {...fieldProps(
                                                                    "type",
                                                                )}
                                                                label={t(
                                                                    "isv-connections.steps.email-notifications.form.notify-about.label",
                                                                )}
                                                                placeholder={t(
                                                                    "general.select",
                                                                )}
                                                                allowClear={
                                                                    false
                                                                }
                                                                options={
                                                                    directionOptions
                                                                }
                                                                initialValue={
                                                                    directionOptions[0]
                                                                        .value
                                                                }
                                                            />
                                                            <Forms.Fields.Checkbox
                                                                {...fieldProps(
                                                                    "success",
                                                                )}
                                                                label={t(
                                                                    "isv-connections.steps.email-notifications.form.success-results.label",
                                                                )}
                                                            />
                                                            <Forms.Fields.Checkbox
                                                                {...fieldProps(
                                                                    "error",
                                                                )}
                                                                label={t(
                                                                    "isv-connections.steps.email-notifications.form.error-results.label",
                                                                )}
                                                            />
                                                            <Separator />
                                                        </div>
                                                    ),
                                                )}
                                                <Gap size="small" />
                                                <Button
                                                    type="tertiary"
                                                    size="small"
                                                    icon={Plus}
                                                    label={t(
                                                        "isv-connections.steps.email-notifications.form.add-another",
                                                    )}
                                                    onClick={() => add()}
                                                    disabled={false}
                                                />
                                            </>
                                        )}
                                    </Forms.List>
                                </Forms.Form>
                            );
                        }}
                    />
                </div>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtonsLeft={
                    <ButtonMenu
                        buttons={[
                            {
                                formId,
                                label: t("general.save"),
                                type: "primary",
                            },
                            {
                                label: t("general.cancel"),
                                onClick: onCancel,
                                type: "text",
                            },
                        ]}
                    />
                }
            />
        </UnityLayout>
    );
};
