import { getEntityLabel } from "@/components/DataUploads/DataUploadsList";
import Changed from "@/components/DateTime/Changed";
import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useParentState } from "@/components/hooks/useParentState.hook";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import {
    useChangeISVMappingStatusMutation,
    useDeleteMapperMutation,
    useMappersListQuery,
} from "@/components/ISVMapping/loadables";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { codeToLabel } from "@/components/UploadQueue/UploadQueue";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getISVMappersTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { defaultTo, map, pipe } from "lodash/fp";
import React, { useCallback, useMemo } from "react";

const preferencesType = preferencesTypes.ISV_MAPPERS_LIST_TABLE;

const createColumns = ({ vendorName, setDataSource }) => [
    {
        type: fieldTypes.TEXT,
        name: "name",
        label: t("isv-connections.data-mapping.list.name"),
    },
    {
        type: fieldTypes.OPTIONS,
        name: "type",
        label: t("isv-connections.data-mapping.list.direction"),
        options: [
            {
                label: t(
                    "isv-connections.data-mapping.step.general.direction.pfx-vendor",
                    {
                        vendorName,
                    },
                ),
                value: MAPPER_DIRECTION.DOWNLOAD,
            },
            {
                label: t(
                    "isv-connections.data-mapping.step.general.direction.vendor-pfx",
                    {
                        vendorName,
                    },
                ),
                value: MAPPER_DIRECTION.UPLOAD,
            },
        ],
        render: type => {
            return type === MAPPER_DIRECTION.DOWNLOAD
                ? t(
                      "isv-connections.data-mapping.step.general.direction.pfx-vendor",
                      {
                          vendorName,
                      },
                  )
                : t(
                      "isv-connections.data-mapping.step.general.direction.vendor-pfx",
                      {
                          vendorName,
                      },
                  );
        },
    },
    {
        type: fieldTypes.TEXT,
        name: "__composedEntity",
        label: t("isv-connections.data-mapping.list.pricefx-tabels"),
    },
    {
        type: fieldTypes.TEXT,
        name: "baseFilePattern",
        label: t("isv-connections.data-mapping.list.enable-table"),
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: t("general.active"), value: true },
            { label: t("general.inactive"), value: false },
        ],
        label: t("event-scheduler.list.columns.enabled"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    useMutation={useChangeISVMappingStatusMutation}
                    from={({ checked }) => ({
                        connectionId: record.id,
                        enabled: checked,
                    })}
                    onChange={enabled =>
                        setDataSource(prev =>
                            prev.map(item =>
                                item.id === record.id
                                    ? { ...item, enabled }
                                    : item,
                            ),
                        )
                    }
                    enabledResponseProp="enabled"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                />
            );
        },
    },
    {
        type: fieldTypes.DATE,
        name: "lastSync",
        label: t("isv-connections.data-mapping.list.enable.last-sync"),
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
];

const ISVDataMappingListPage = () => {
    const {
        locationRouterService,
        accountAppLocations: {
            isvDataMappingCreationLocation,
            isvDataMappingEditLocation,
        },
    } = useDic();
    const { isvConnectionLoadable } = useRouteISVConnection();

    const refetchRouteData = useRouteDataRefetch();

    const { accountId, isvConnectionId } = useAccountAppParams();

    useBreadcrumbButton(
        {
            label: "location.accountApp.isvMappers.create",
            onClick: () =>
                locationRouterService.navigate(isvDataMappingCreationLocation, {
                    isvConnectionId,
                }),
            track: { name: getTrackName("New") },
        },
        [],
    );

    const mappersListResource = useMappersListQuery({ isvConnectionId });
    const deleteMapperMutation = useDeleteMapperMutation({
        afterSuccess: () => {
            refetchRouteData();
            mappersListResource.reload();
        },
    });
    useFullWidthTableLayout();

    const navigateToEdit = useCallback(
        record => {
            locationRouterService.navigate(isvDataMappingEditLocation, {
                accountId,
                isvConnectionId,
                isvMapperId: record.id,
            });
        },
        [
            locationRouterService,
            isvDataMappingEditLocation,
            accountId,
            isvConnectionId,
        ],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.edit"),
                        onClick: navigateToEdit,
                    },
                    {
                        title: t(
                            "isv-connections.data-mapping.list.action.delete",
                        ),
                        confirmMessage: t(
                            "general.do-you-really-want-to-delete-name",
                            { name: record.name },
                        ),
                        onConfirm: deleteMapperMutation.mutate,
                        color: "red",
                    },
                ]}
            />
        ),
        [deleteMapperMutation.mutate, navigateToEdit],
    );
    const vendorMaybe = isvConnectionLoadable.valueMaybe()?.vendor;

    const dataSourceInit = useMemo(
        () =>
            pipe(
                defaultTo([]),
                map(row => ({
                    ...row,
                    __composedEntity: getEntityLabel({
                        entityType: codeToLabel(row.pfxEntityType),
                        entityName: row.pfxEntityName,
                    }),
                })),
            )(mappersListResource.loadable.valueMaybe()),
        [mappersListResource],
    );

    const [dataSource, setDataSource] = useParentState(dataSourceInit);

    const columns = useMemo(
        () =>
            createColumns({
                vendorName: mapVendorToVendorName(vendorMaybe),
                setDataSource,
            }),
        [vendorMaybe, setDataSource],
    );

    return (
        <TableWithPreferencesManagement
            loading={isLoading(mappersListResource.loadable)}
            dataSource={dataSource}
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            columns={columns}
            pagination={false}
            fixed
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};

export default ISVDataMappingListPage;
