import { useCurrentRef } from "@/components/hooks/useCurrentRef.hook";
import { hasValue, LoadingLoadable } from "@/modules/loadable";
import {
    HasErrorLoadable,
    HasValueLoadable,
} from "@/modules/loadable/useQueryLoadable.hook";
import { SecurityContext } from "@/security/authorization";
import { useCheckFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import {
    includesPermission,
    ISV_CONNECTION_EDIT_PERMISSION,
} from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { setWith } from "lodash/fp";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useRoute } from "react-router5";
import { useDic } from "../components/Dic/useDic.hook";

export const RouteDataContext = React.createContext({});

const isDefaultParam = value => value === "-1" || value === -1;

const ACTIONS = {
    FECH_START: "FECH_START",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_ERROR: "FETCH_ERROR",
    SET_CURRENT_ROUTE: "SET_CURRENT_ROUTE",
};

const initialState = {
    resources: {},
    currentRoute: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.FECH_START: {
            return setWith(
                Object,
                [
                    "resources",
                    action.meta.resourceName,
                    action.meta.name,
                    `${action.meta.value}`,
                ],
                LoadingLoadable(),
                state,
            );
        }
        case ACTIONS.FETCH_SUCCESS: {
            return setWith(
                Object,
                [
                    "resources",
                    action.meta.resourceName,
                    action.meta.name,
                    `${action.meta.value}`,
                ],
                HasValueLoadable(action.payload),
                state,
            );
        }
        case ACTIONS.FETCH_ERROR: {
            return setWith(
                Object,
                [
                    "resources",
                    action.meta.resourceName,
                    action.meta.name,
                    `${action.meta.value}`,
                ],
                HasErrorLoadable(action.error),
                state,
            );
        }
        case ACTIONS.SET_CURRENT_ROUTE: {
            return { ...state, currentRoute: action.payload };
        }
        default:
            return state;
    }
};

export const fetchers = {
    accountId: {
        account: {
            fetcher: ({ axiosService }, accountId) =>
                axiosService.get(`/api/projects/${accountId}`).then(getData),
            // Endpoint is not cached because BE sets default accountId by it's calling https://pricefx.atlassian.net/browse/PFIM-5958
            isCached: false,
        },
        accountInstances: {
            fetcher: ({ axiosService }, accountId) =>
                axiosService
                    .get(`/api/projects/${accountId}/instances`)
                    .then(getData),
        },
        accountPartitions: {
            fetcher: ({ axiosService }, accountId) =>
                axiosService
                    .get(`/api/projects/${accountId}/partition-assets`)
                    .then(getData),
        },
        accountIsvConnections: {
            fetcher: ({ isvConnectionService }, accountId) =>
                isvConnectionService.listConnections(accountId),
            permission: ISV_CONNECTION_EDIT_PERMISSION,
            featureFlag: "isv-connection",
        },
    },
    instanceId: {
        instance: {
            fetcher: ({ axiosService }, id) =>
                axiosService.get(`/api/instances/${id}`).then(getData),
        },
    },
    partitionId: {
        partitionAssets: {
            fetcher: (
                { axiosService },
                id,
                { accountId }, // TODO: consider
            ) =>
                axiosService
                    .get(`/api/projects/${accountId}/partition-assets/${id}`)
                    .then(getData),
        },
    },
    isvConnectionId: {
        connection: {
            fetcher: ({ isvConnectionService }, id) =>
                isvConnectionService.getConnection(id),
        },
    },
};

export const getResource = (resourceName, paramName, paramValue, resources) =>
    resources?.[resourceName]?.[paramName]?.[paramValue] ?? LoadingLoadable();

const routeIdHasChanged = (routeId, route, previousRoute) =>
    route?.params?.[routeId] !== previousRoute?.params?.[routeId];

export const RouteDataContextProvider = ({ children }) => {
    const { axiosService, isvConnectionService } = useDic();
    const routeInfo = useRoute();
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);
    stateRef.current = state;

    const isEnabledFeature = useCheckFeatureFlag();
    const securityContextValue = useContext(SecurityContext);
    const securityContextValueRef = useCurrentRef(securityContextValue);

    const fetchParamData = useCallback(
        (route, resourceName) => (name, value) => {
            const fetchAction = fetchers[name][resourceName]?.fetcher;
            if (!fetchAction) {
                console.log(
                    `%c[RouteDataContextProvider] Cannot fetch data for route param ${name}=${value}`,
                    "color:coral;",
                );
                return;
            }
            dispatch({
                type: ACTIONS.FETCH_START,
                meta: { name, value, route, resourceName },
            });
            fetchAction(
                { axiosService, isvConnectionService },
                value,
                route.params,
            )
                .then(data =>
                    dispatch({
                        type: ACTIONS.FETCH_SUCCESS,
                        meta: { name, value, route, resourceName },
                        payload: data,
                    }),
                )
                .catch(error =>
                    dispatch({
                        type: ACTIONS.FETCH_ERROR,
                        meta: { name, value, route, resourceName },
                        error,
                    }),
                );
        },
        [axiosService],
    );

    const onRouteChange = useCallback(
        (routeInfo, forceReload = false) => {
            const { route, previousRoute } = routeInfo;
            Object.entries(route.params)
                .filter(([, value]) => {
                    return value && !isDefaultParam(value);
                })
                .forEach(([routeId, value]) => {
                    Object.keys(fetchers[routeId] ?? {})
                        .filter(key => {
                            const permission =
                                fetchers[routeId][key]?.permission;
                            if (permission) {
                                return includesPermission(
                                    securityContextValueRef.current,
                                    [permission],
                                );
                            }
                            return true;
                        })
                        .filter(key => {
                            const featureFlag =
                                fetchers[routeId][key]?.featureFlag;
                            if (featureFlag) {
                                return isEnabledFeature(featureFlag);
                            }
                            return true;
                        })
                        .forEach(resourceName => {
                            const isCached =
                                fetchers[routeId][resourceName]?.isCached ??
                                true;
                            if (
                                (!isCached &&
                                    routeIdHasChanged(
                                        routeId,
                                        route,
                                        previousRoute,
                                    )) ||
                                !hasValue(
                                    getResource(
                                        resourceName,
                                        routeId,
                                        value,
                                        stateRef.current.resources,
                                    ),
                                ) ||
                                forceReload
                            ) {
                                fetchParamData(route, resourceName)(
                                    routeId,
                                    value,
                                );
                            }
                        });
                });

            dispatch({ type: ACTIONS.SET_CURRENT_ROUTE, payload: route });
        },
        [fetchParamData],
    );

    const refetchRouteData = useCallback(
        () => onRouteChange(routeInfo, true),
        [onRouteChange, routeInfo],
    );

    useEffect(() => {
        onRouteChange(routeInfo);
    }, [routeInfo, dispatch, onRouteChange]);

    const context = useMemo(
        () => ({
            currentRoute: state.currentRoute,
            resources: state.resources,
            refetchRouteData,
        }),
        [state, refetchRouteData],
    );

    return (
        <RouteDataContext.Provider value={context}>
            {children}
        </RouteDataContext.Provider>
    );
};

RouteDataContextProvider.propTypes = {
    children: PropTypes.node,
};
