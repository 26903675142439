import { ButtonMenu, Forms, UnityLayout } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useIsvConnectionNameValidator } from "@/components/ISVConnections/helpers";
import { mapValuesToConnection } from "@/components/ISVConnections/ISVConnection.page";
import {
    useEditConnectionMutation,
    useISVAllowedPartitionsQuery,
} from "@/components/ISVConnections/loadables";
import { mapPartitionsToOptions } from "@/components/Marketplace/helpers";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useRouteAccountPartitions } from "@/mixpanel/hooks/useRouteAccountPartitions.hook";
import {
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
    useQueryLoadable,
} from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { t } from "@/translations";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React, { useCallback, useMemo } from "react";

const { Form, Fields, pmValidators } = Forms;

export const ISV_CONNECTION_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

const DEFAULT_SAML_CONFIG_NAME = "DEFAULT";

export const useSamlConfigurationQuery = ({ partitionId }) => {
    const { partitionAssetsService } = useDic();

    return useQueryLoadable(
        async () =>
            partitionId
                ? partitionAssetsService.getSamlConfiguration(partitionId)
                : [],
        [partitionAssetsService, partitionId],
    );
};

export const neededSfxParameters = [
    "channelId",
    "tradingProgramBaseUrl",
    "collaboratorBaseUrl",
    "host",
    "port",
    "username",
];

export const normalizeSftpSfxParameters = sfxParameters => {
    return {
        channelId: sfxParameters.channelId,
        baseUrl: sfxParameters.tradingProgramBaseUrl,
        collaboratorBaseUrl: sfxParameters.collaboratorBaseUrl,
        sftpUrl: sfxParameters.host,
        sftpPort: sfxParameters.port,
        sftpUsername: sfxParameters.username,
    };
};

const useIsPartitionContainSalesFxParameters = ({ accountId }) => {
    const { partitionService } = useDic();

    return useCallback(
        async partitionId => {
            if (!partitionId) {
                return Forms.success();
            }
            const partitionInfo = await partitionService.getPartition(
                accountId,
                partitionId,
            );

            const availableSfxParameters = Object.keys(
                partitionInfo?.sfxSyncData,
            );

            const missingSfxParameters = neededSfxParameters.filter(
                param =>
                    !availableSfxParameters.includes(param) ||
                    partitionInfo?.sfxSyncData[param] === null,
            );

            if (
                neededSfxParameters.every(
                    param =>
                        availableSfxParameters.includes(param) &&
                        partitionInfo?.sfxSyncData[param] !== null,
                )
            ) {
                return Forms.success();
            }

            return Forms.error(
                `Information about the Enable channel is missing in Salesfx for the selected partition. Ask the responsible person to fill it in.`,
            );
        },
        [accountId, partitionService],
    );
};

const GeneralStepFields = ({ form, stepProps, allStepsProps }) => {
    const { accountPartitionsLoadable } = useRouteAccountPartitions();
    const formId = Forms.useFormId();

    const partitionId = useFieldValue({ formId, name: "partitionId" });
    const currentSamlConfiguration = useFieldValue({
        formId,
        name: "samlConfiguration",
    });

    const allowedPartitionsQuery = useISVAllowedPartitionsQuery({
        accountId: allStepsProps.accountId,
    });

    const partitionsLoadable = useComposeLoadablesMemoized([
        accountPartitionsLoadable,
        allowedPartitionsQuery.loadable,
    ]);

    const partitionsToEditLoadable = useMapLoadableMemoized(
        partitionsLoadable,
        ([allPartitions, allowedPartitions]) => {
            const allowedPartitionIds = allowedPartitions.map(
                partition => partition.id,
            );

            return allPartitions.filter(
                partition =>
                    allowedPartitionIds.includes(partition.id) ||
                    partition.id === partitionId,
            );
        },
    );

    const partitionsOptionsLoadable = useMapLoadableMemoized(
        partitionsToEditLoadable,
        mapPartitionsToOptions,
    );

    const isPartitionContainSalesFxParameters =
        useIsPartitionContainSalesFxParameters({
            accountId: allStepsProps.accountId,
        });

    const samlConfigurationResource = useSamlConfigurationQuery({
        partitionId,
    });

    const samlConfigurationOptionsLoadable = useMapLoadableMemoized(
        samlConfigurationResource.loadable,
        configs =>
            configs.map(configurationName => ({
                label: configurationName,
                value: configurationName,
            })),
    );

    const isvConnectionNameValdator = useIsvConnectionNameValidator({
        accountId: allStepsProps.accountId,
        initialValues: stepProps?.initialValues,
    });

    useLoadableHasValueChangedEffect(
        samlConfigurationOptionsLoadable,
        samlConfigurations => {
            // preselect default saml configuration if it is not set
            if (!currentSamlConfiguration) {
                form.setValues({
                    samlConfiguration:
                        samlConfigurations.find(
                            config => config.value === DEFAULT_SAML_CONFIG_NAME,
                        )?.value ?? undefined,
                });
            }
        },
        [currentSamlConfiguration, form, samlConfigurationOptionsLoadable],
    );

    const resetSamlField = () => {
        form.setValues({
            samlConfiguration: undefined,
        });
    };

    const partitionValidators = useMemo(
        () =>
            Forms.validators.failOnFirst([
                pmValidators.isRequired,
                debounceAsync(500, isPartitionContainSalesFxParameters),
            ]),
        [isPartitionContainSalesFxParameters],
    );

    return (
        <div style={{ maxWidth: "426px" }}>
            <Fields.Input
                disabled={allStepsProps.isEdit}
                inputWidth="max"
                label={t("isv-connections.steps.general.name")}
                name="name"
                required
                validator={isvConnectionNameValdator}
            />

            <Fields.Select
                disabled={allStepsProps.isEdit}
                initialValue={"ENABLE"}
                inputWidth="max"
                label={t("isv-connections.steps.general.vendor")}
                name="vendor"
                options={[{ label: "ENABLE", value: "ENABLE" }]}
                required
                validator={pmValidators.isRequired}
                // {...getLoadableSelectProps(transportsEnvListLoadable)}
            />

            <Fields.Select
                label={t("isv-connections.steps.general.partition")}
                name="partitionId"
                required
                validator={partitionValidators}
                inputWidth="max"
                {...getLoadableSelectProps(partitionsOptionsLoadable)}
                onChange={resetSamlField}
            />

            <Fields.Select
                label={t("isv-connections.steps.general.saml-configuration")}
                name="samlConfiguration"
                required
                allowClear={false}
                validator={pmValidators.isRequired}
                inputWidth="max"
                {...getLoadableSelectProps(samlConfigurationOptionsLoadable)}
            />

            <Fields.Radio
                name="state"
                label={t("isv-connections.steps.general.status")}
                initialValue={ISV_CONNECTION_STATUS.ACTIVE}
                options={[
                    {
                        label: t("general.active"),
                        value: ISV_CONNECTION_STATUS.ACTIVE,
                    },
                    {
                        label: t("general.inactive"),
                        value: ISV_CONNECTION_STATUS.INACTIVE,
                    },
                ]}
            />
        </div>
    );
};

export const GeneralStepEditForm = ({
    accountId,
    initialValues,
    onSuccess,
    onCancel,
}) => {
    const editConnectionMutation = useEditConnectionMutation({
        connectionId: initialValues.id,
        afterSuccess: onSuccess,
    });

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) =>
            editConnectionMutation.mutate(
                mapValuesToConnection({
                    projectId: accountId,
                    ...initialValues,
                    ...values,
                }),
            ),
    });

    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    return (
        <UnityLayout>
            <UnityLayout.Content padding={[true, true, false, true]}>
                <Form formId={formId} onSubmit={handleSubmit}>
                    <GeneralStepFields
                        form={{
                            setValues,
                        }}
                        allStepsProps={{
                            accountId,
                            isEdit: true,
                        }}
                        stepProps={{
                            initialValues,
                        }}
                    />
                </Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtonsLeft={
                    <ButtonMenu
                        buttons={[
                            {
                                formId,
                                label: t("general.save"),
                                type: "primary",
                            },
                            {
                                label: t("general.cancel"),
                                onClick: onCancel,
                                type: "text",
                            },
                        ]}
                    />
                }
            />
        </UnityLayout>
    );
};

export default GeneralStepFields;
