import Changed from "@/components/DateTime/Changed";
import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useParentState } from "@/components/hooks/useParentState.hook";
import {
    useChangeISVConnectionStatusMutation,
    useConnectionsListQuery,
    useDeleteConnectionMutation,
} from "@/components/ISVConnections/loadables";
import { ISV_CONNECTION_STATUS } from "@/components/ISVConnections/steps/General.step";
import { mapPartitionsToOptions } from "@/components/Marketplace/helpers";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getISVConnectionsTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { useRouteAccountPartitions } from "@/mixpanel/hooks/useRouteAccountPartitions.hook";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { hasValue, isLoading } from "@/modules/loadable";
import { LocationLink } from "@/modules/router";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { filter, isEmpty, map, negate, pipe, sortBy, uniq } from "lodash/fp";
import React, { useCallback, useMemo } from "react";

const preferencesType = preferencesTypes.ISV_CONNECTIONS_LIST_TABLE;

const createColumns = ({
    accountId,
    partitionsById,
    partitionOptions,
    overviewLocation,
    setDataSource,
}) => [
    {
        type: fieldTypes.TEXT,
        name: "name",
        label: t("isv-connections.list.name"),

        render: (name, record) => (
            <LocationLink
                key={record.id}
                $location={overviewLocation}
                $params={{
                    accountId,
                    isvConnectionId: record.id,
                }}
            >
                {name}
            </LocationLink>
        ),
    },
    {
        type: fieldTypes.TEXT,
        name: "vendor",
        label: t("isv-connections.list.vendor"),
    },
    {
        type: fieldTypes.OPTIONS,
        name: "state",
        label: t("isv-connections.list.status"),
        options: [
            {
                value: ISV_CONNECTION_STATUS.ACTIVE,
                label: t("general.active"),
            },
            {
                value: ISV_CONNECTION_STATUS.INACTIVE,
                label: t("general.inactive"),
            },
        ],
        render: (state, record) => {
            return (
                <SwitchAsync
                    initialValue={state === ISV_CONNECTION_STATUS.ACTIVE}
                    useMutation={useChangeISVConnectionStatusMutation}
                    from={({ checked }) => ({
                        connectionId: record.id,
                        state: checked
                            ? ISV_CONNECTION_STATUS.ACTIVE
                            : ISV_CONNECTION_STATUS.INACTIVE,
                    })}
                    onChange={checked =>
                        setDataSource(prev =>
                            prev.map(item =>
                                item.id === record.id
                                    ? {
                                          ...item,
                                          state: checked
                                              ? ISV_CONNECTION_STATUS.ACTIVE
                                              : ISV_CONNECTION_STATUS.INACTIVE,
                                      }
                                    : item,
                            ),
                        )
                    }
                    enabledResponseProp="state"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                />
            );
        },
    },
    {
        type: fieldTypes.OPTIONS,
        name: "partitionId",
        label: t("isv-connections.list.partition"),
        options: partitionOptions,
        render: (text, record) =>
            partitionsById[record.partitionId]?.serialNumber,
    },
    {
        type: fieldTypes.DATE,
        name: "updatedAt",
        label: t("isv-connections.list.last-change"),
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
    // {
    //     type: fieldTypes.TEXT,
    //     name: "updatedBy",
    //     label: t("isv-connections.list.date-connected"),
    //     canSort: false,
    //     canFilter: false,
    // },
];

const ISVConnectionsListPage = () => {
    const {
        locationRouterService,
        accountAppLocations: {
            isvConnectionsNewLocation,
            isvConnectionsOverviewLocation,
            isvConnectionsSettingsLocation,
        },
    } = useDic();

    const refetchRouteData = useRouteDataRefetch();
    const { accountPartitionsLoadable } = useRouteAccountPartitions();

    const partitionsById = (
        accountPartitionsLoadable.valueMaybe() ?? []
    ).reduce((acc, partition) => ({ [partition.id]: partition, ...acc }), {});

    const { accountId } = useAccountAppParams();

    useBreadcrumbButton(
        {
            label: "location.accountApp.isvConnections.create",
            onClick: () =>
                locationRouterService.navigate(isvConnectionsNewLocation),
            track: { name: getTrackName("New") },
        },
        [],
    );
    const connectionsListResource = useConnectionsListQuery({ accountId });

    const dataSourceInit = connectionsListResource.loadable.valueMaybe() ?? [];
    const [dataSource, setDataSource] = useParentState(dataSourceInit);

    const deleteConnectionMutation = useDeleteConnectionMutation({
        afterSuccess: () => {
            refetchRouteData();
            connectionsListResource.reload();
        },
    });
    useFullWidthTableLayout();

    const navigateToSettings = useCallback(
        record => {
            locationRouterService.navigate(isvConnectionsSettingsLocation, {
                accountId,
                isvConnectionId: record.id,
            });
        },
        [locationRouterService, isvConnectionsSettingsLocation, accountId],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.edit"),
                        onClick: navigateToSettings,
                    },
                    {
                        title: t("general.delete"),
                        confirmMessage: t(
                            "general.do-you-really-want-to-delete-name",
                            { name: record.name },
                        ),
                        onConfirm: deleteConnectionMutation.mutate,
                        color: "red",
                    },
                ]}
            />
        ),
        [deleteConnectionMutation.mutate, navigateToSettings],
    );

    const partitionOptions = useMemo(
        () =>
            hasValue(connectionsListResource.loadable) &&
            hasValue(accountPartitionsLoadable)
                ? pipe(
                      map(connection => connection.partitionId),
                      uniq,
                      map(partitionId => partitionsById[partitionId]),
                      filter(negate(isEmpty)),
                      mapPartitionsToOptions,
                      sortBy("label"),
                  )(connectionsListResource.loadable.valueMaybe())
                : [],
        [
            accountPartitionsLoadable,
            connectionsListResource.loadable,
            partitionsById,
        ],
    );

    return (
        <TableWithPreferencesManagement
            loading={isLoading(connectionsListResource.loadable)}
            dataSource={dataSource}
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            columns={createColumns({
                accountId,
                partitionOptions,
                partitionsById,
                overviewLocation: isvConnectionsOverviewLocation,
                setDataSource,
            })}
            pagination={false}
            fixed
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};

export default ISVConnectionsListPage;
