import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useCreateConnectionMutation } from "@/components/ISVConnections/loadables";
import GeneralStepFields, {
    neededSfxParameters,
    normalizeSftpSfxParameters,
} from "@/components/ISVConnections/steps/General.step";
import { PricefxStep } from "@/components/ISVConnections/steps/Pricefx.step";
import VendorStepFields, {
    usePartitionAssets,
} from "@/components/ISVConnections/steps/Vendor.step";
import { StepForm } from "@/components/StepForm";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { pick } from "lodash/fp";
import React, { useCallback, useState } from "react";

const ISV_CONNECTION_STEP_KEY = {
    GENERAL: "general",
    VENDOR: "vendor",
    PRICEFX: "pricefx",
};

export const mapValuesToConnection = values => {
    const {
        type,
        channelId,
        baseUrl,
        sftpUrl,
        sftpUsername,
        sftpPassword,
        sftpPort,
        vendor,
        samlConfiguration,
        collaboratorBaseUrl,
        ...restValues
    } = values;
    return {
        ...restValues,
        setting: {
            samlConfiguration,
            vendor,
            type,
            channelId,
            baseUrl,
            collaboratorBaseUrl,
            sftpUrl,
            sftpUsername,
            sftpPassword,
            sftpPort,
        },
    };
};

export const mapConnectionToInitValues = connection => {
    const { setting, ...rest } = connection;
    return {
        ...setting,
        ...rest,
    };
};

const withSalesfxParams = Component => props => {
    const {
        form: { formId, setValues, setTouched },
    } = props;
    const { accountId } = useAccountAppParams();

    const partitionId = useFieldValue({
        formId,
        name: "partitionId",
    });

    const partitionAssetsResource = usePartitionAssets({
        accountId,
        partitionId,
    });

    const sfxParameters = normalizeSftpSfxParameters(
        pick(
            neededSfxParameters,
            partitionAssetsResource.loadable.valueMaybe()?.sfxSyncData,
        ),
    );

    useSetValidatedInitialValues(
        {
            initialValues: sfxParameters,
            setValues,
            setTouched,
        },
        [sfxParameters],
    );

    return <Component {...props} />;
};

const ISVConnectionPage = ({ initialValues = {} }) => {
    const { accountId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: {
            isvConnectionsLocation,
            isvConnectionsOverviewLocation,
        },
    } = useDic();

    useFullWidthTableLayout();
    const refetchRouteData = useRouteDataRefetch();
    const [createdConnectionId, setCreatedConnectionId] = useState();

    const navigateToOverview = useCallback(
        isvConnectionId => {
            locationRouterService.navigate(isvConnectionsOverviewLocation, {
                accountId,
                isvConnectionId,
            });
        },
        [locationRouterService, isvConnectionsOverviewLocation, accountId],
    );
    const navigateToList = useCallback(() => {
        locationRouterService.navigate(isvConnectionsLocation, {
            accountId,
        });
    }, [locationRouterService, isvConnectionsLocation, accountId]);

    const createConnectionMutation = useCreateConnectionMutation({
        afterSuccess: ({ id }) => {
            refetchRouteData();
            setCreatedConnectionId(id);
        },
    });

    const handleSave = useCallback(({ values }) => {
        const payload = mapValuesToConnection(values);
        return createConnectionMutation.mutate({
            projectId: accountId,
            ...payload,
        });
    }, []);

    const getStepButtons = useCallback(
        ({ stepperProps, formId, submit }) => [
            {
                visible: !!createdConnectionId,
                label: t("general.close"),
                type: "primary",
                onClick: () => {
                    navigateToOverview(createdConnectionId);
                },
            },
            {
                visible:
                    stepperProps.currentStep.key ===
                    ISV_CONNECTION_STEP_KEY.VENDOR,
                label: t("general.create"),
                type: "primary",
                onClick: () =>
                    submit(({ values }) =>
                        handleSave({ values }).then(stepperProps.nextStep),
                    ),
                disabled: isLoading(createConnectionMutation),
                loading: isLoading(createConnectionMutation),
            },
            {
                visible: stepperProps.isFirstStep,
                label: t("general.continue"),
                type: "primary",
                formId,
            },
            {
                visible: !stepperProps.isFirstStep && !createdConnectionId,
                label: t("general.back"),
                type: "secondary",
                onClick: stepperProps.prevStep,
            },
            {
                visible: !createdConnectionId,
                label: t("general.cancel"),
                type: "text",
                onClick: navigateToList,
                "data-test": "cancel-button",
            },
        ],
        [
            createConnectionMutation,
            createdConnectionId,
            handleSave,
            navigateToList,
            navigateToOverview,
        ],
    );

    return (
        <>
            <StepForm
                title={t("location.accountApp.isvConnections.new")}
                initialValues={initialValues}
                // defaultActiveTab={ISV_CONNECTION_STEP_KEY.VENDOR}
                steps={[
                    {
                        key: ISV_CONNECTION_STEP_KEY.GENERAL,
                        title: t("isv-connections.stepper.general"),
                        Component: GeneralStepFields,
                        stepProps: {
                            initialValues,
                        },
                    },
                    {
                        key: ISV_CONNECTION_STEP_KEY.VENDOR,
                        title: t("isv-connections.stepper.vendor"),
                        Component: withSalesfxParams(VendorStepFields),
                    },
                    {
                        key: ISV_CONNECTION_STEP_KEY.PRICEFX,
                        title: t("isv-connections.stepper.pricefx"),
                        Component: PricefxStep,
                        stepProps: {
                            isvConnectionId: createdConnectionId,
                        },
                    },
                ]}
                onCancel={navigateToList}
                allStepsProps={{
                    accountId,
                }}
                getStepButtons={getStepButtons}
                isTabVariant={false}
                onSubmit={handleSave} // Tab change -> save
            />
        </>
    );
};

export default ISVConnectionPage;
