import { Button } from "@/components/DesignSystem";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import cx from "classnames";
import React from "react";
import "./DeleteRowButton.styles.less";

export const DeleteRowButton = ({ className, readOnly, ...props }) => {
    return (
        <Button
            type="tertiary"
            danger
            className={cx("pmDeleteRowButton", className)}
            disabled={readOnly}
            icon={Trash}
            {...props}
        />
    );
};
