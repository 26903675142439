import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { defaultQsOptions } from "@/services/constants";
import { getData } from "@/services/utils";
import { noop, tap } from "lodash/fp";
import qs from "qs";

export const useNotificationsListQuery = ({ accountId, isvConnectionId }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            isNaN(isvConnectionId)
                ? pendingPromise()
                : axiosService
                      .get(
                          `/api/simple-notifications${qs.stringify(
                              { entityId: isvConnectionId, accountId },
                              defaultQsOptions,
                          )}`,
                      )
                      .then(getData),
        [accountId, axiosService, isvConnectionId],
    );
};

export const useCreateOrUpdateNotificationMutation = ({
    isvConnectionId,
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async notifications =>
            axiosService
                .post(`/api/simple-notifications`, {
                    entityId: isvConnectionId,
                    accountId,
                    notifications,
                })
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService, isvConnectionId],
        "isv-connections.steps.email-notifications.success",
    );
};
