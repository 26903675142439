import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import React from "react";
import { AlertCellWithPopover } from "../AlertCellWithPopover/AlertCellWithPopover";
import FormattedDateTime from "../DateTime/FormattedDateTime";
import { ButtonMenu, Modal, UnityLayout } from "../DesignSystem";
import { fieldTypes } from "../DesignSystem/Table/constants";
import { useLastSyncErrorsQuery } from "../ISVMapping/loadables";
import { TableLocalFiltered } from "../TableLocalFiltered";

const ERROR_COLUMNS = [
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.errors.column.timestamp"),
        name: "timestamp",
        render: text => (
            <AlertCellWithPopover
                iconVisible
                type="critical"
                content={<FormattedDateTime>{text}</FormattedDateTime>}
            />
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.errors.column.message"),
        name: "message",
    },
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.errors.column.payload"),
        name: "payload",
    },
];

const arr = [];
export const ISVErrorsModal = ({ onClose, record }) => {
    const query = useLastSyncErrorsQuery({
        isvConnectionId: record?.id,
    });
    const dataSource = query.loadable.valueMaybe() || arr;
    // prepared for pagination, BE not ready:
    // const { isvMappingService } = useDic();
    // const [tableProps] = useFetchPage(
    //     (page, size, sort, filter) =>
    //         // !record?.id ? Promise.resolve({}) :
    //         isvMappingService.lastSyncErrors(record.id, {
    //             page,
    //             size,
    //             sort,
    //             filter,
    //         }),
    //     [record?.id],
    // );
    console.log("[ISVErrorModal.rndr]", {
        record,
        // tableProps,
    });

    return (
        <Modal visible={!!record} onClose={onClose} width={950}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("isv.overview.overview.column.errors")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {!!record && (
                        // <PageableTable
                        // {...tableProps}
                        <TableLocalFiltered
                            rowKey="id"
                            columns={ERROR_COLUMNS}
                            dataSource={dataSource}
                            loading={isLoading(query.loadable)}
                            fixed
                            fixedHeight={400}
                        />
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: onClose,
                                    type: "primary",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
